<template>
  <div>
    <div class="page-content py-5">
      <div class="container">
        <loading-page :isShow="isShowLoading"></loading-page>
        <div v-if="!isShowLoading" class="pb-4 row">
          <!--title-->
          <h1
            :title="project.title"
            class="project-list-title fs-5 px-2 fw-bold order-0"
          >
            {{ project.title }}
          </h1>
          <!--//title-->
          <!--image-->
          <div
            class="
              thumable p-2 position-relative w-lg-50 col-12 col-md-6 order-2 order-md-2
            "
          >
            <img
              v-if="project.image && project.video === ''"
              lazysizes
              :data-src="project.image.medium_large"
              :src="project.image.medium_large"
              class="project-list-img img-fluid"
              :alt="project.title"
              width="768"
              height="768"
              srcset=""
            />

            <link itemprop="thumbnailUrl" href="url_image" />
            <embed v-if="project.video !== ''" style="width: 400px;height: 719px;" :src="project.video"></embed>
            <span
              itemprop="thumbnail"
              itemscope
              itemtype="http://schema.org/ImageObject"
            >
              <link
                v-if="project.image"
                itemprop="url"
                :href="project.image.medium"
              />
            </span>
            <div v-if="!isShowReadme" v-html="project.excerpt"></div>
            <div
              class="text-justify my-2"
              v-if="project.description && isShowReadme"
              v-html="project.description"
            ></div>
            <button
              @click.prevent="readMore()"
              class="mt-2 btn edc-btn-primary rounded-pill text-white"
            >
              {{ read_more_text }}
            </button>
          </div>

          <!--//image-->
          <div class="col-12 col-md-6 order-1 order-md-1">

            <div
              class="
                project-options-box
                bottom-0
                mb-4
                d-flex
                flex-wrap
                align-items-center
              "
            >
              <div
                v-if="project.is_completed"
                class="
                  donation_done
                  edc-gradient-green
                  rounded-pill
                  text-white
                  fs-7
                "
              >
                <i class="fas fa-check"></i>

                {{ $t("btn.donated") }}
              </div>
              <div
                v-if="project.is_approved_zaka"
                class="
                  zakat_accept
                  edc-gradient-blue
                  rounded-pill
                  text-white
                  fs-7
                "
              >
                <i class="fas fa-check"></i>
                {{ $t("btn.zakat_is_permissible") }}
              </div>
              <!-- is alradey flowin us class btn-fllowed-->
              <button
                v-if="!project.is_wishlist && !project.is_completed"
                :data-id="project.id"
                aria-label="Add to follow"
                @click.prevent="followProject()"
                class="
                  follow
                  btn-fllowing
                  edc-gradient-yellow
                  rounded-pill
                  text-white
                  fs-7
                "
              >
                <i class="far fa-heart"></i>
                {{ $t("btn.follow") }}
              </button>
              <button
                v-if="project.is_wishlist"
                :data-id="project.id"
                aria-label="Add to wishlist"
                @click.prevent="followProject()"
                class="
                  follow
                  edc-gradient-yellow
                  rounded-pill
                  text-white
                  fs-7
                  btn-fllowed
                "
              >
                <i class="far fa-heart"></i>
                {{ $t("btn.un_follow") }}
              </button>
            </div>
            <!--progress-->

            <progress-bar
              v-if="project.meta && project.meta.target > 0"
              :project_id="project.id"
              :percentage="project.meta.percent"
            ></progress-bar>
            <!--// progress-->
            <div>
              <!--project amount list-->
              <div v-if="project.is_completed === false">
                <div class="px-3">{{ $t("label.add_amount") }}</div>
                <div class="d-flex gap-3 my-2 justify-content-center px-3">
                  <button
                    class="flex-fill btn edc-btn-primary text-white"
                    @click.prevent="addAmount(amount)"
                    v-for="(amount, index) in project.amounts"
                    :key="index"
                  >
                    {{ amount }}
                  </button>
                </div>
              </div>
              <!--//project amount list-->
              <div class="project-content-left">
                <!--project price-->
                <div
                  class="project-price px-3 mt-2 fs-7"
                  v-if="project.is_completed === false"
                >
                  <div
                    class="
                      project-price-box
                      my-2
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    <div
                      class="
                        project-price-box-up-down
                        bg-white
                        edc-border-primary
                        rounded-pill
                        d-flex
                        align-items-stretch align-self-stretch
                      "
                    >
                      <button
                        type="button"
                        :data-id="project.id"
                        @click="priceChange(project, '+')"
                        class="
                          up
                          border-start border-0
                          bg-transparent
                          edc-color-green
                          px-3
                        "
                        aria-label="incress amount"
                      >
                        <i class="fas fa-plus p-3"></i>
                      </button>
                      <button
                        type="button"
                        :data-id="project.id"
                        @click="priceChange(project, '-')"
                        class="down border-0 bg-transparent edc-color-red px-3"
                        aria-label="decress amount"
                      >
                        <i class="fas fa-minus p-3"></i>
                      </button>
                    </div>
                    <div
                      class="
                        project-price-box-input
                        d-flex
                        flex-fill
                        justify-content-between
                        align-items-center
                        bg-white
                        edc-border-primary
                        px-3
                        py-2
                        me-1
                        w-75 w-lg-100
                        rounded-pill
                        align-items-center
                      "
                    >
                      <input
                        type="text"
                        :id="'price-input-' + project.id"
                        class="
                          price_input
                          border-0
                          fs-5
                          w-100
                          flex-fill
                          edc-color-green
                          bg-transparent
                          text-center
                        "
                        placeholder="00.000"
                        v-model="amount"
                        @keyup="inputChange(amount)"
                      />
                      <span class="kd fs-4">{{ project.currency }}</span>
                    </div>
                  </div>
                </div>
                <!--//project price-->
                <!--project buttons-->
                <div
                  class="project-buttons px-3 mt-2"
                  v-if="
                    project.is_completed === false && project.is_gift === false
                  "
                >
                  <div class="d-grid gap-2">
                    <fast-donation-area :project_data="project" :amount="parseInt(amount)" :markater="markater"  :gift="gift_input"/>
                    <add-to-cart :project_data="project" :amount="parseInt(amount)" :markater="markater"  :gift="gift_input"/>
                  </div>
                </div>
                <div
                  v-if="
                    project.is_completed === false && project.is_gift === true
                  "
                >
                  <div class="row">
                    <div class="col-6 mb-2">
                      <label
                        for="name"
                        class="mx-2 form-label edc-color-primary"
                        >{{ $t("gift_input.name") }}</label
                      >
                      <span class="text-danger">*</span>
                      <input
                        class="form-control rounded-pill py-2"
                        type="text"
                        name="name"
                        :placeholder="$t('gift_input.name')"
                        v-model="gift_input.name"
                        id="name"
                      />
                    </div>
                    <div class="col-6 mb-2">
                      <label
                        for="email"
                        class="mx-2 form-label edc-color-primary"
                        >{{ $t("gift_input.email") }}</label
                      >
                      <span class="text-danger">*</span>
                      <input
                        class="form-control rounded-pill py-2"
                        type="email"
                        name="email"
                        :placeholder="$t('gift_input.email')"
                        v-model="gift_input.email"
                        id="email"
                      />
                    </div>
                    <div class="col-6 mb-2">
                      <label
                        for="to_name"
                        class="mx-2 form-label edc-color-primary"
                        >{{ $t("gift_input.to_name") }}</label
                      >
                      <span class="text-danger">*</span>
                      <input
                        class="form-control rounded-pill py-2"
                        type="text"
                        name="to_name"
                        :placeholder="$t('gift_input.to_name')"
                        v-model="gift_input.to_name"
                        id="to_name"
                      />
                    </div>
                    <div class="col-6 mb-2">
                      <label
                        for="to_email"
                        class="mx-2 form-label edc-color-primary"
                        >{{ $t("gift_input.to_email") }}</label
                      >

                      <input
                        class="form-control rounded-pill py-2"
                        type="email"
                        name="to_email"
                        :placeholder="$t('gift_input.to_email')"
                        v-model="gift_input.to_email"
                        id="to_email"
                      />
                    </div>
                    <div class="d-grid gap-2">
                      <button
                        @click="sendGift($event, amount, project, markater)"
                        class="
                          btn
                          edc-btn-blue
                          rounded-pill
                          btn-lg btn-block
                          py-3
                          text-center text-white
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                      >
                        <i class="fas fa-coins mx-1"></i>
                        <i class="fas fa-paper-plane mx-1"></i>
                        {{ $t("btn.send_gift") }}
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="isMobile() === true"
                    class="row mt-2 position-relative"
                    style="width: 360px; margin: auto"
                  >
                    <div
                      style="margin-top: 117px"
                      class="position-absolute d-flex text-danger"
                    >
                      <h2
                        class="text-right"
                        style="margin-start: 45px; font-size: 11px; width: 50%"
                      >
                        {{ this.gift_input.name }}
                      </h2>
                      <h2
                        class="text-right"
                        style="font-size: 11px; width: 50%"
                      >
                        {{ this.gift_input.to_name }}
                      </h2>
                    </div>
                    <img
                      src="@/assets/images/shahada-tawade.png"
                      style="width: 360px"
                      alt="وقف الدعوة الإلكترونية"
                    />
                  </div>
                </div>
                <!--//project buttons-->
              </div>
            </div>
            <div class="project-content-center">
              <!--project group and muslims statistics-->
              <groups-and-muslims-statistics class="my-2" :project="project" />
              <!--//project group and muslims statistics-->
              <div class="">
                <!-- project meta-->
                <div
                  class="project-value fs-6 d-flex justify-content-between p-3"
                  v-if="project.meta && parseInt(project.meta.total) >= 0"
                >
                  <div class="project-value-title edc-color-black fw-bold">
                    {{ $t("label.amount_collected") }}
                  </div>
                  <div
                    class="project-value-money edc-color-black fw-bold d-flex"
                    v-if="project.meta"
                  >
                    <count-up :endVal="parseInt(project.meta.total)">
                      {{ project.currency }}
                    </count-up>
                  </div>
                </div>
                <div
                  class="project-value fs-6 d-flex justify-content-between p-3"
                  v-if="project.meta && project.meta.residual > 0"
                >
                  <div class="project-value-title edc-color-black fw-bold">
                    {{ $t("label.remaining_amount") }}
                  </div>
                  <div
                    class="project-value-money edc-color-black fw-bold d-flex"
                  >
                    <count-up :endVal="parseInt(project.meta.residual)">
                      {{ project.currency }}
                    </count-up>
                  </div>
                </div>
                <!--//project meta-->
              </div>
              <!--project value-->
              <div
                class="project-value fs-5 d-flex justify-content-between py-3"
                v-if="project.meta && project.meta.target > 0"
              >
                <div
                  class="project-value-title edc-color-primary fw-bold d-flex"
                >
                  {{ $t("label.project_value") }}
                </div>
                <div
                  class="project-value-money edc-color-primary fw-bold d-flex"
                >
                  <count-up :endVal="parseInt(project.meta.target)">
                    {{ project.currency }}
                  </count-up>
                </div>
              </div>
              <!--//project value-->


              <ShareSocial :image="sahreImage" :title="project.title" :description="project.excerpt"></ShareSocial>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProgressBar from "@/components/ProgressBar";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import store from "../store/index";
import LoadingPage from "../components/LoadingPage.vue";
import ShareSocial from "../components/ShareSocial.vue";

import vueLazysizes from "vue-lazysizes";
import FastDonationArea from "@/components/ProjectCardItems/FastDonationArea.vue";
import KnetPayButton from "@/components/ProjectCardItems/KnetPayButton.vue";
import GroupsAndMuslimsStatistics from "@/components/ProjectItem/GroupsAndMuslimsStatistics.vue";
import AddToCart from "@/components/ProjectCardItems/AddToCart.vue";
import Embed from 'v-video-embed'
export default {
  name: "ProjectPage",

  data() {
    return {
      sahreImage: null,
      show: false,
      amount: 20,
      markater: this.$route.query.mk,
      read_more_text: this.$t("btn.read_more"),
      isShowReadme: false,
      isShowLoading: true,
      gift_input: {
        name: "",
        email: "",
        to_name: "",
        to_email: "",
      },
    };
  },

  computed: {
    ...mapGetters("project", ["project"]),
  },
  methods: {
    ...mapActions("project", ["getProject", "resetProject"]),
    ...mapActions("cart", ["addToCart"]),
    priceChange(item, type) {
      if (type === "+") {
        if (this.amount < 10000) {
          this.amount++;
        } else {
          this.amount = 10000;
        }
      } else if (type === "-") {
        if (this.amount > 1) {
          this.amount--;
        } else {
          this.amount = 1;
        }
      }
    },
    inputChange(amount) {
      if (amount > 10000) {
        this.amount = 10000;
      }
      if (amount < 1) {
        this.amount = 1;
      }
    },
    addAmount(amount) {
      this.amount = amount;
    },
    readMore() {
      if (this.isShowReadme === false) {
        this.isShowReadme = true;
        this.read_more_text = this.$t("btn.read_less");
      } else {
        this.isShowReadme = false;
        this.read_more_text = this.$t("btn.read_more");
      }
    },
    doAddToCart(amount, project) {
      if (this.amount <= 0) {
        this.$swal({
          icon: "error",
          title: this.$t("message.must_insert_amount"),
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        return false;
      }
      this.addToCart({ id: project.id, amount: amount,mk: this.$route.query.mk })
        .then((response) => {
          //console.log(response);
          //this.getCart();
          this.$swal({
            icon: "success",
            title: this.$t("message.add_to_cart_success"),
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        })
        .catch((error) => {
          if (error) {
            this.$swal({
              icon: "error",
              title: this.$t("message.add_to_cart_fail"),
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          }
        });
    },
    fastDonate(amount, project, mk) {
      if (amount <= 0) {
        this.$swal({
          icon: "error",
          title: this.$t("message.must_insert_amount"),
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        return false;
      }
      this.$root.$emit("doFastDonate", {
        amount: amount,
        project: project,
        mk: mk,
        gift: this.gift_input,
      });
    },
    followProject() {
      axios
        .post("add-to-wishlist", { id: this.project.id, user_id: 1 })
        .then((response) => {
          if (response.status === 200) {
            //console.log(response.data);
            this.$swal({
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            this.project.is_wishlist = response.data.is_follow;
          }
        })
        .catch((error) => {
          //console.log(error);
          if (error) {
            this.$swal({
              icon: "error",
              title: error.response.data.message,
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          }
        });
    },
    sendGift(event, amount, project, markater) {
      event.preventDefault();
      if (this.gift_input.name == "" || this.gift_input.to_name == "") {
        this.$swal({
          icon: "error",
          title: this.$t("message.all_fields_is_requierd"),
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
      } else if (!this.validEmail(this.gift_input.email)) {
        this.$swal({
          icon: "error",
          title: this.$t("message.all_correct_email"),
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
      } else {
        let payload = {
          name: this.gift_input.name,
          email: this.gift_input.email,
          to_name: this.gift_input.to_name,
          to_email: this.gift_input.to_email,
        };
        this.fastDonate(amount, project, markater);
        //console.log(event, payload);
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
  components: {
    AddToCart,
    GroupsAndMuslimsStatistics, KnetPayButton, FastDonationArea, ProgressBar,  LoadingPage, ShareSocial },
  created() {
    //store.commit("project/SET_PROJECT", []);
    this.resetProject();
    this.getProject({ slug: this.$route.params.slug })
      .then((res) => {
        if (res.status === 200) {
          store.commit("project/SET_PROJECT", res.data);
          this.sahreImage = this.project.image.medium_large;
          this.amount = res.data.fixed_amount;
        } else {
          this.$router.push("/404");
        }
      })
      .catch((error) => {
        this.$router.push("/404");
      })
      .finally(() => {
        this.isShowLoading = false;
      });
  },
    mounted() {
        let that = this;
        this.$root.$on("updateProjectAmountDetails",function (payload,id) {
            if(that.project !== null && id === that.project.id){
                that.project.meta = payload;
            }
        });
    },
    directives: {
    lazysizes: vueLazysizes
  },
  metaInfo() {
    return {
      titleTemplate: this.project.title + " | %s",
      /*meta: [
        {
          vmid: "description",
          name: "description",
          content: this.project.excerpt,
        },
        { vmid: "og:title", property: "og:title", content: this.project.title },
        { vmid: "og:type", property: "og:type", content: "website" },
        { vmid: "og:url", property: "og:url", content: window.location.href }, // here it is just ngrok for my test
        {
          vmid: "og:description",
          property: "og:description",
          content: this.project.excerpt,
        },
        {
          vmid: "og:image",
          property: "og:image",
          itemprop: "image",
          content: this.project.ogimage,
        },
        {
          vmid: "og:image:secure_url",
          property: "og:image:secure_url",
          content: this.project.ogimage,
        },
        {
          vmid: "og:image:width",
          property: "og:image:width",
          content: "300",
        },
        {
          vmid: "og:image:height",
          property: "og:image:height",
          content: "300",
        },
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          vmid: "twitter:domain",
          name: "twitter:domain",
          content: "edc.org.kw",
        },
        {
          vmid: "twitter:url",
          property: "twitter:url",
          content: window.location.href,
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.project.title,
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.project.excerpt,
        },
        {
          vmid: "twitter:image:src",
          property: "twitter:image:src",
          content: this.project.ogimage,
        },
        {
          vmid: "og:image:width",
          property: "og:image:width",
          content: "1200",
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: this.project.title,
        },
      ],*/
    };
  },
};
</script>